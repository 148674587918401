import React from "react"

export default function AbsoluteImage({ children, ...props }) {
  return (
    <div style={{ position: "relative" }}>
      <img {...props} />
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          style: { ...child.props.style, position: "absolute" },
        })
      })}
    </div>
  )
}
