import Layout from "./common/layout"
import Head from "./common/Head"
import OpolloDetail from "./product/Integrated/OpolloDetail"
import { useFormatter } from "helpers/i18n"

const ProductOpollo = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Opollo - Omni-Channel Oder Management System"
            : "Opollo - Hệ thống quản lý bán hàng đa kênh"
        }
        description={
          currentLocale === "en"
            ? "Your New Bridge to the E-Commerce Promised Land"
            : "Your New Bridge to the E-Commerce Promised Land"
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/product-2-meta.png"
        }
      />
      <Layout>
        <OpolloDetail />
      </Layout>
    </>
  )
}

export default ProductOpollo
