import ContainerMain from "pages/common/ContainerMain"

import imageEn from "images/Product/Opollo/opollo-delivery-desktop-en.png"
import imageVi from "images/Product/Opollo/opollo-delivery-desktop-vi.png"
import "./Deliver.scss"
import { useFormatter } from "helpers/i18n"
import { useMemo } from "react"

export default function DeliverOpollo() {
  const { __, currentLocale } = useFormatter()

  const image = useMemo(
    () => (currentLocale === "vi" ? imageVi : imageEn),
    [currentLocale]
  )

  const contents1 = [
    {
      title: `${__({ defaultMessage: "Centralization​" })}`,

      description: `${__({
        defaultMessage:
          "API to connect multiple brands, marketplaces, storefronts, warehouses, and third-party logistics providers to sync and display data in one place.​",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Peak Performance​" })}`,
      description: `${__({
        defaultMessage:
          "The best-in-class uptime and server response to serve and easy to scale for the big campaigns, result in increasing sales dramatically.​",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Automation​​" })}`,
      description: `${__({
        defaultMessage:
          "Provide automatic on management, marketing, live support, services.",
      })}`,
    },
  ]

  const contents2 = [
    {
      title: `${__({ defaultMessage: "Real-time synchronization" })}`,

      description: `${__({
        defaultMessage:
          "Instant updates on order status, inventory levels, and fulfillment progress—eliminating delays, reducing errors, and enabling better decision-making to keep your operations running smoothly at all times.",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Low Cost To Serve​" })}`,
      description: `${__({
        defaultMessage:
          "Best pricing, no setup fee, no integration fee, pay as you grow with successful order only.​",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Robust Security​" })}`,
      description: `${__({
        defaultMessage:
          "Security standard, strict access authorization control. Role based access control for teams, stores.",
      })}`,
    },
  ]

  return (
    <>
      <div className="hjjwtlvigw">
        <ContainerMain>
          <div className="txszrulamf">
            <div className="kuixqwbvwz">
              {__({ defaultMessage: " What We Deliver" })}
            </div>
            <div className="wtrorqvjqq" style={{ alignItems: "start" }}>
              <div className="qcrqunblqy" style={{ marginBottom: 0 }}>
                {contents1.map((item, key) => {
                  return (
                    <div className="iyjsbvljrv" key={key}>
                      <div className="feonoypzfk">
                        <div className="nwwwyopygt">{item.title}</div>
                        <div className="kvrcetuodm">{item.description}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="bhfderjjqb">
                <img src={image} alt="" className="pkisauhgre hover01" />
              </div>
              <div className="vxlnrhopnx">
                {contents2.map((item, key) => {
                  return (
                    <div className="iyjsbvljrv" key={key}>
                      <div className="feonoypzfk">
                        <div className="nwwwyopygt">{item.title}</div>
                        <div className="kvrcetuodm">{item.description}</div>
                      </div>
                    </div>
                  )
                })}
                <div className="bhfderjjqb">
                  <img src={image} alt="" className="pkisauhgre-2 hover01" />
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
