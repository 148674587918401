exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ace-your-gmv-js": () => import("./../../../src/pages/ace-your-gmv.js" /* webpackChunkName: "component---src-pages-ace-your-gmv-js" */),
  "component---src-pages-channels-brand-brand-js": () => import("./../../../src/pages/channels/Brand/Brand.js" /* webpackChunkName: "component---src-pages-channels-brand-brand-js" */),
  "component---src-pages-channels-brand-components-news-brand-js": () => import("./../../../src/pages/channels/Brand/components/NewsBrand.js" /* webpackChunkName: "component---src-pages-channels-brand-components-news-brand-js" */),
  "component---src-pages-channels-brand-js": () => import("./../../../src/pages/channels-brand.js" /* webpackChunkName: "component---src-pages-channels-brand-js" */),
  "component---src-pages-channels-marketplace-components-sider-new-js": () => import("./../../../src/pages/channels/Marketplace/components/SiderNew.js" /* webpackChunkName: "component---src-pages-channels-marketplace-components-sider-new-js" */),
  "component---src-pages-channels-marketplace-js": () => import("./../../../src/pages/channels-marketplace.js" /* webpackChunkName: "component---src-pages-channels-marketplace-js" */),
  "component---src-pages-channels-marketplace-marketplace-js": () => import("./../../../src/pages/channels/Marketplace/Marketplace.js" /* webpackChunkName: "component---src-pages-channels-marketplace-marketplace-js" */),
  "component---src-pages-channels-social-commerce-components-model-concept-2-js": () => import("./../../../src/pages/channels/SocialCommerce/components/ModelConcept2.js" /* webpackChunkName: "component---src-pages-channels-social-commerce-components-model-concept-2-js" */),
  "component---src-pages-channels-social-commerce-components-model-concept-js": () => import("./../../../src/pages/channels/SocialCommerce/components/ModelConcept.js" /* webpackChunkName: "component---src-pages-channels-social-commerce-components-model-concept-js" */),
  "component---src-pages-channels-social-commerce-components-our-products-js": () => import("./../../../src/pages/channels/SocialCommerce/components/OurProducts.js" /* webpackChunkName: "component---src-pages-channels-social-commerce-components-our-products-js" */),
  "component---src-pages-channels-social-commerce-components-partner-js": () => import("./../../../src/pages/channels/SocialCommerce/components/Partner.js" /* webpackChunkName: "component---src-pages-channels-social-commerce-components-partner-js" */),
  "component---src-pages-channels-social-commerce-components-solutions-js": () => import("./../../../src/pages/channels/SocialCommerce/components/Solutions.js" /* webpackChunkName: "component---src-pages-channels-social-commerce-components-solutions-js" */),
  "component---src-pages-channels-social-commerce-js": () => import("./../../../src/pages/channels-social-commerce.js" /* webpackChunkName: "component---src-pages-channels-social-commerce-js" */),
  "component---src-pages-channels-social-commerce-social-commerce-js": () => import("./../../../src/pages/channels/SocialCommerce/SocialCommerce.js" /* webpackChunkName: "component---src-pages-channels-social-commerce-social-commerce-js" */),
  "component---src-pages-clients-partner-clients-partner-js": () => import("./../../../src/pages/ClientsPartner/ClientsPartner.js" /* webpackChunkName: "component---src-pages-clients-partner-clients-partner-js" */),
  "component---src-pages-clients-partner-components-logo-footer-v-2-js": () => import("./../../../src/pages/ClientsPartner/components/LogoFooterV2.js" /* webpackChunkName: "component---src-pages-clients-partner-components-logo-footer-v-2-js" */),
  "component---src-pages-clients-partner-js": () => import("./../../../src/pages/clients-partner.js" /* webpackChunkName: "component---src-pages-clients-partner-js" */),
  "component---src-pages-common-banner-header-js": () => import("./../../../src/pages/common/BannerHeader.js" /* webpackChunkName: "component---src-pages-common-banner-header-js" */),
  "component---src-pages-common-container-main-js": () => import("./../../../src/pages/common/ContainerMain.js" /* webpackChunkName: "component---src-pages-common-container-main-js" */),
  "component---src-pages-common-content-js": () => import("./../../../src/pages/common/Content.js" /* webpackChunkName: "component---src-pages-common-content-js" */),
  "component---src-pages-common-content-wrapper-js": () => import("./../../../src/pages/common/ContentWrapper.js" /* webpackChunkName: "component---src-pages-common-content-wrapper-js" */),
  "component---src-pages-common-custom-image-js": () => import("./../../../src/pages/common/CustomImage.js" /* webpackChunkName: "component---src-pages-common-custom-image-js" */),
  "component---src-pages-common-footer-page-js": () => import("./../../../src/pages/common/FooterPage.js" /* webpackChunkName: "component---src-pages-common-footer-page-js" */),
  "component---src-pages-common-get-start-js": () => import("./../../../src/pages/common/GetStart.js" /* webpackChunkName: "component---src-pages-common-get-start-js" */),
  "component---src-pages-common-gift-modal-js": () => import("./../../../src/pages/common/GiftModal.js" /* webpackChunkName: "component---src-pages-common-gift-modal-js" */),
  "component---src-pages-common-head-js": () => import("./../../../src/pages/common/Head.js" /* webpackChunkName: "component---src-pages-common-head-js" */),
  "component---src-pages-common-image-zoom-effect-js": () => import("./../../../src/pages/common/ImageZoomEffect.js" /* webpackChunkName: "component---src-pages-common-image-zoom-effect-js" */),
  "component---src-pages-common-layout-js": () => import("./../../../src/pages/common/Layout.js" /* webpackChunkName: "component---src-pages-common-layout-js" */),
  "component---src-pages-common-menu-header-item-js": () => import("./../../../src/pages/common/MenuHeaderItem.js" /* webpackChunkName: "component---src-pages-common-menu-header-item-js" */),
  "component---src-pages-common-menu-header-js": () => import("./../../../src/pages/common/MenuHeader.js" /* webpackChunkName: "component---src-pages-common-menu-header-js" */),
  "component---src-pages-common-section-title-js": () => import("./../../../src/pages/common/SectionTitle.js" /* webpackChunkName: "component---src-pages-common-section-title-js" */),
  "component---src-pages-common-sub-title-js": () => import("./../../../src/pages/common/SubTitle.js" /* webpackChunkName: "component---src-pages-common-sub-title-js" */),
  "component---src-pages-common-template-2-colums-js": () => import("./../../../src/pages/common/Template2Colums.js" /* webpackChunkName: "component---src-pages-common-template-2-colums-js" */),
  "component---src-pages-company-about-about-js": () => import("./../../../src/pages/company/About/About.js" /* webpackChunkName: "component---src-pages-company-about-about-js" */),
  "component---src-pages-company-about-components-achievement-js": () => import("./../../../src/pages/company/About/components/Achievement.js" /* webpackChunkName: "component---src-pages-company-about-components-achievement-js" */),
  "component---src-pages-company-about-components-leadership-js": () => import("./../../../src/pages/company/About/components/Leadership.js" /* webpackChunkName: "component---src-pages-company-about-components-leadership-js" */),
  "component---src-pages-company-about-components-mission-js": () => import("./../../../src/pages/company/About/components/Mission.js" /* webpackChunkName: "component---src-pages-company-about-components-mission-js" */),
  "component---src-pages-company-about-components-our-history-js": () => import("./../../../src/pages/company/About/components/OurHistory.js" /* webpackChunkName: "component---src-pages-company-about-components-our-history-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company-about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-ace-your-gmv-index-js": () => import("./../../../src/pages/company/AceYourGmv/index.js" /* webpackChunkName: "component---src-pages-company-ace-your-gmv-index-js" */),
  "component---src-pages-company-blog-blog-js": () => import("./../../../src/pages/company/Blog/Blog.js" /* webpackChunkName: "component---src-pages-company-blog-blog-js" */),
  "component---src-pages-company-blog-components-list-article-js": () => import("./../../../src/pages/company/Blog/components/ListArticle.js" /* webpackChunkName: "component---src-pages-company-blog-components-list-article-js" */),
  "component---src-pages-company-blog-components-recent-post-js": () => import("./../../../src/pages/company/Blog/components/RecentPost.js" /* webpackChunkName: "component---src-pages-company-blog-components-recent-post-js" */),
  "component---src-pages-company-blog-components-tabnew-js": () => import("./../../../src/pages/company/Blog/components/Tabnew.js" /* webpackChunkName: "component---src-pages-company-blog-components-tabnew-js" */),
  "component---src-pages-company-blog-js": () => import("./../../../src/pages/company-blog.js" /* webpackChunkName: "component---src-pages-company-blog-js" */),
  "component---src-pages-company-career-career-js": () => import("./../../../src/pages/company/Career/Career.js" /* webpackChunkName: "component---src-pages-company-career-career-js" */),
  "component---src-pages-company-career-components-benefit-detail-js": () => import("./../../../src/pages/company/Career/components/BenefitDetail.js" /* webpackChunkName: "component---src-pages-company-career-components-benefit-detail-js" */),
  "component---src-pages-company-career-components-filter-jobs-js": () => import("./../../../src/pages/company/Career/components/FilterJobs.js" /* webpackChunkName: "component---src-pages-company-career-components-filter-jobs-js" */),
  "component---src-pages-company-career-components-human-of-on-point-js": () => import("./../../../src/pages/company/Career/components/HumanOfOnPoint.js" /* webpackChunkName: "component---src-pages-company-career-components-human-of-on-point-js" */),
  "component---src-pages-company-career-components-human-of-on-point-v-2-js": () => import("./../../../src/pages/company/Career/components/HumanOfOnPointV2.js" /* webpackChunkName: "component---src-pages-company-career-components-human-of-on-point-v-2-js" */),
  "component---src-pages-company-career-components-introduction-js": () => import("./../../../src/pages/company/Career/components/Introduction.js" /* webpackChunkName: "component---src-pages-company-career-components-introduction-js" */),
  "component---src-pages-company-career-components-job-item-js": () => import("./../../../src/pages/company/Career/components/JobItem.js" /* webpackChunkName: "component---src-pages-company-career-components-job-item-js" */),
  "component---src-pages-company-career-components-job-opening-js": () => import("./../../../src/pages/company/Career/components/JobOpening.js" /* webpackChunkName: "component---src-pages-company-career-components-job-opening-js" */),
  "component---src-pages-company-career-components-our-benefits-js": () => import("./../../../src/pages/company/Career/components/OurBenefits.js" /* webpackChunkName: "component---src-pages-company-career-components-our-benefits-js" */),
  "component---src-pages-company-career-components-our-office-js": () => import("./../../../src/pages/company/Career/components/OurOffice.js" /* webpackChunkName: "component---src-pages-company-career-components-our-office-js" */),
  "component---src-pages-company-career-components-our-people-intro-js": () => import("./../../../src/pages/company/Career/components/OurPeopleIntro.js" /* webpackChunkName: "component---src-pages-company-career-components-our-people-intro-js" */),
  "component---src-pages-company-career-components-our-people-js": () => import("./../../../src/pages/company/Career/components/OurPeople.js" /* webpackChunkName: "component---src-pages-company-career-components-our-people-js" */),
  "component---src-pages-company-career-js": () => import("./../../../src/pages/company-career.js" /* webpackChunkName: "component---src-pages-company-career-js" */),
  "component---src-pages-company-common-arrow-js": () => import("./../../../src/pages/company/common/Arrow.js" /* webpackChunkName: "component---src-pages-company-common-arrow-js" */),
  "component---src-pages-company-detail-media-components-comment-js": () => import("./../../../src/pages/company/DetailMedia/components/Comment.js" /* webpackChunkName: "component---src-pages-company-detail-media-components-comment-js" */),
  "component---src-pages-company-detail-media-detail-media-award-js": () => import("./../../../src/pages/company/DetailMedia/DetailMediaAward.js" /* webpackChunkName: "component---src-pages-company-detail-media-detail-media-award-js" */),
  "component---src-pages-company-detail-media-detail-media-best-managed-companies-js": () => import("./../../../src/pages/company/DetailMedia/DetailMediaBestManagedCompanies.js" /* webpackChunkName: "component---src-pages-company-detail-media-detail-media-best-managed-companies-js" */),
  "component---src-pages-company-detail-media-detail-media-buy-back-company-crea-js": () => import("./../../../src/pages/company/DetailMedia/DetailMediaBuyBackCompanyCrea.js" /* webpackChunkName: "component---src-pages-company-detail-media-detail-media-buy-back-company-crea-js" */),
  "component---src-pages-company-detail-media-detail-media-js": () => import("./../../../src/pages/company/DetailMedia/DetailMedia.js" /* webpackChunkName: "component---src-pages-company-detail-media-detail-media-js" */),
  "component---src-pages-company-media-components-library-js": () => import("./../../../src/pages/company/Media/components/Library.js" /* webpackChunkName: "component---src-pages-company-media-components-library-js" */),
  "component---src-pages-company-media-components-press-coverage-js": () => import("./../../../src/pages/company/Media/components/PressCoverage.js" /* webpackChunkName: "component---src-pages-company-media-components-press-coverage-js" */),
  "component---src-pages-company-media-components-press-release-js": () => import("./../../../src/pages/company/Media/components/PressRelease.js" /* webpackChunkName: "component---src-pages-company-media-components-press-release-js" */),
  "component---src-pages-company-media-js": () => import("./../../../src/pages/company-media.js" /* webpackChunkName: "component---src-pages-company-media-js" */),
  "component---src-pages-company-media-media-js": () => import("./../../../src/pages/company/Media/Media.js" /* webpackChunkName: "component---src-pages-company-media-media-js" */),
  "component---src-pages-company-mt-program-index-js": () => import("./../../../src/pages/company/MtProgram/index.js" /* webpackChunkName: "component---src-pages-company-mt-program-index-js" */),
  "component---src-pages-home-components-clients-js": () => import("./../../../src/pages/home/components/Clients.js" /* webpackChunkName: "component---src-pages-home-components-clients-js" */),
  "component---src-pages-home-components-clients-number-js": () => import("./../../../src/pages/home/components/ClientsNumber.js" /* webpackChunkName: "component---src-pages-home-components-clients-number-js" */),
  "component---src-pages-home-components-discover-on-point-js": () => import("./../../../src/pages/home/components/DiscoverOnPoint.js" /* webpackChunkName: "component---src-pages-home-components-discover-on-point-js" */),
  "component---src-pages-home-components-news-js": () => import("./../../../src/pages/home/components/News.js" /* webpackChunkName: "component---src-pages-home-components-news-js" */),
  "component---src-pages-home-components-our-products-js": () => import("./../../../src/pages/home/components/OurProducts.js" /* webpackChunkName: "component---src-pages-home-components-our-products-js" */),
  "component---src-pages-home-components-our-services-js": () => import("./../../../src/pages/home/components/OurServices.js" /* webpackChunkName: "component---src-pages-home-components-our-services-js" */),
  "component---src-pages-home-components-slider-home-js": () => import("./../../../src/pages/home/components/SliderHome.js" /* webpackChunkName: "component---src-pages-home-components-slider-home-js" */),
  "component---src-pages-home-components-talk-our-js": () => import("./../../../src/pages/home/components/TalkOur.js" /* webpackChunkName: "component---src-pages-home-components-talk-our-js" */),
  "component---src-pages-home-components-what-we-do-js": () => import("./../../../src/pages/home/components/WhatWeDo.js" /* webpackChunkName: "component---src-pages-home-components-what-we-do-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-job-name-js": () => import("./../../../src/pages/jobs/{Job.name}.js" /* webpackChunkName: "component---src-pages-jobs-job-name-js" */),
  "component---src-pages-livestream-js": () => import("./../../../src/pages/livestream.js" /* webpackChunkName: "component---src-pages-livestream-js" */),
  "component---src-pages-management-trainee-program-js": () => import("./../../../src/pages/management-trainee-program.js" /* webpackChunkName: "component---src-pages-management-trainee-program-js" */),
  "component---src-pages-media-best-managed-companies-js": () => import("./../../../src/pages/media/best-managed-companies.js" /* webpackChunkName: "component---src-pages-media-best-managed-companies-js" */),
  "component---src-pages-media-buy-back-company-crea-js": () => import("./../../../src/pages/media/buy-back-company-crea.js" /* webpackChunkName: "component---src-pages-media-buy-back-company-crea-js" */),
  "component---src-pages-media-entrepreneur-award-js": () => import("./../../../src/pages/media/entrepreneur-award.js" /* webpackChunkName: "component---src-pages-media-entrepreneur-award-js" */),
  "component---src-pages-media-press-release-js": () => import("./../../../src/pages/media/press-release.js" /* webpackChunkName: "component---src-pages-media-press-release-js" */),
  "component---src-pages-privacy-policy-components-privacy-policy-en-js": () => import("./../../../src/pages/privacyPolicy/components/PrivacyPolicyEN.js" /* webpackChunkName: "component---src-pages-privacy-policy-components-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-components-privacy-policy-vn-js": () => import("./../../../src/pages/privacyPolicy/components/PrivacyPolicyVN.js" /* webpackChunkName: "component---src-pages-privacy-policy-components-privacy-policy-vn-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-campaign-campaign-js": () => import("./../../../src/pages/product/Campaign/Campaign.js" /* webpackChunkName: "component---src-pages-product-campaign-campaign-js" */),
  "component---src-pages-product-campaign-components-model-concept-2-js": () => import("./../../../src/pages/product/Campaign/components/ModelConcept2.js" /* webpackChunkName: "component---src-pages-product-campaign-components-model-concept-2-js" */),
  "component---src-pages-product-campaign-components-model-concept-js": () => import("./../../../src/pages/product/Campaign/components/ModelConcept.js" /* webpackChunkName: "component---src-pages-product-campaign-components-model-concept-js" */),
  "component---src-pages-product-campaign-components-new-js": () => import("./../../../src/pages/product/Campaign/components/New.js" /* webpackChunkName: "component---src-pages-product-campaign-components-new-js" */),
  "component---src-pages-product-campaign-components-recommentdation-js": () => import("./../../../src/pages/product/Campaign/components/Recommentdation.js" /* webpackChunkName: "component---src-pages-product-campaign-components-recommentdation-js" */),
  "component---src-pages-product-campaign-js": () => import("./../../../src/pages/product-campaign.js" /* webpackChunkName: "component---src-pages-product-campaign-js" */),
  "component---src-pages-product-common-what-we-offer-template-js": () => import("./../../../src/pages/product/Common/WhatWeOfferTemplate.js" /* webpackChunkName: "component---src-pages-product-common-what-we-offer-template-js" */),
  "component---src-pages-product-common-why-new-js": () => import("./../../../src/pages/product/Common/WhyNew.js" /* webpackChunkName: "component---src-pages-product-common-why-new-js" */),
  "component---src-pages-product-common-why-octo-js": () => import("./../../../src/pages/product/Common/WhyOcto.js" /* webpackChunkName: "component---src-pages-product-common-why-octo-js" */),
  "component---src-pages-product-common-why-product-js": () => import("./../../../src/pages/product/Common/WhyProduct.js" /* webpackChunkName: "component---src-pages-product-common-why-product-js" */),
  "component---src-pages-product-integrated-components-business-js": () => import("./../../../src/pages/product/Integrated/components/Business.js" /* webpackChunkName: "component---src-pages-product-integrated-components-business-js" */),
  "component---src-pages-product-integrated-components-business-opollo-js": () => import("./../../../src/pages/product/Integrated/components/BusinessOpollo.js" /* webpackChunkName: "component---src-pages-product-integrated-components-business-opollo-js" */),
  "component---src-pages-product-integrated-components-deliver-js": () => import("./../../../src/pages/product/Integrated/components/Deliver.js" /* webpackChunkName: "component---src-pages-product-integrated-components-deliver-js" */),
  "component---src-pages-product-integrated-components-deliver-opollo-js": () => import("./../../../src/pages/product/Integrated/components/DeliverOpollo.js" /* webpackChunkName: "component---src-pages-product-integrated-components-deliver-opollo-js" */),
  "component---src-pages-product-integrated-integrated-js": () => import("./../../../src/pages/product/Integrated/Integrated.js" /* webpackChunkName: "component---src-pages-product-integrated-integrated-js" */),
  "component---src-pages-product-integrated-js": () => import("./../../../src/pages/product-integrated.js" /* webpackChunkName: "component---src-pages-product-integrated-js" */),
  "component---src-pages-product-integrated-opollo-detail-js": () => import("./../../../src/pages/product/Integrated/OpolloDetail.js" /* webpackChunkName: "component---src-pages-product-integrated-opollo-detail-js" */),
  "component---src-pages-product-opollo-js": () => import("./../../../src/pages/product-opollo.js" /* webpackChunkName: "component---src-pages-product-opollo-js" */),
  "component---src-pages-product-performance-js": () => import("./../../../src/pages/product-performance.js" /* webpackChunkName: "component---src-pages-product-performance-js" */),
  "component---src-pages-product-performance-performance-js": () => import("./../../../src/pages/product/Performance/Performance.js" /* webpackChunkName: "component---src-pages-product-performance-performance-js" */),
  "component---src-pages-product-store-js": () => import("./../../../src/pages/product-store.js" /* webpackChunkName: "component---src-pages-product-store-js" */),
  "component---src-pages-product-store-store-js": () => import("./../../../src/pages/product/Store/Store.js" /* webpackChunkName: "component---src-pages-product-store-store-js" */),
  "component---src-pages-service-common-box-background-js": () => import("./../../../src/pages/service/Common/BoxBackground.js" /* webpackChunkName: "component---src-pages-service-common-box-background-js" */),
  "component---src-pages-service-common-live-chat-js": () => import("./../../../src/pages/service/Common/LiveChat.js" /* webpackChunkName: "component---src-pages-service-common-live-chat-js" */),
  "component---src-pages-service-common-logo-footer-js": () => import("./../../../src/pages/service/Common/LogoFooter.js" /* webpackChunkName: "component---src-pages-service-common-logo-footer-js" */),
  "component---src-pages-service-common-new-row-js": () => import("./../../../src/pages/service/Common/NewRow.js" /* webpackChunkName: "component---src-pages-service-common-new-row-js" */),
  "component---src-pages-service-common-what-we-do-js": () => import("./../../../src/pages/service/Common/WhatWeDo.js" /* webpackChunkName: "component---src-pages-service-common-what-we-do-js" */),
  "component---src-pages-service-common-what-we-do-v-2-js": () => import("./../../../src/pages/service/Common/WhatWeDoV2.js" /* webpackChunkName: "component---src-pages-service-common-what-we-do-v-2-js" */),
  "component---src-pages-service-common-what-we-do-v-3-js": () => import("./../../../src/pages/service/Common/WhatWeDoV3.js" /* webpackChunkName: "component---src-pages-service-common-what-we-do-v-3-js" */),
  "component---src-pages-service-engagement-components-crm-js": () => import("./../../../src/pages/service/Engagement/components/CRM.js" /* webpackChunkName: "component---src-pages-service-engagement-components-crm-js" */),
  "component---src-pages-service-engagement-engagement-js": () => import("./../../../src/pages/service/Engagement/Engagement.js" /* webpackChunkName: "component---src-pages-service-engagement-engagement-js" */),
  "component---src-pages-service-engagement-js": () => import("./../../../src/pages/service-engagement.js" /* webpackChunkName: "component---src-pages-service-engagement-js" */),
  "component---src-pages-service-livestream-index-js": () => import("./../../../src/pages/service/Livestream/index.js" /* webpackChunkName: "component---src-pages-service-livestream-index-js" */),
  "component---src-pages-service-livestream-thank-you-js": () => import("./../../../src/pages/service/Livestream/ThankYou.js" /* webpackChunkName: "component---src-pages-service-livestream-thank-you-js" */),
  "component---src-pages-service-logistics-js": () => import("./../../../src/pages/service-logistics.js" /* webpackChunkName: "component---src-pages-service-logistics-js" */),
  "component---src-pages-service-logistics-logistics-js": () => import("./../../../src/pages/service/Logistics/Logistics.js" /* webpackChunkName: "component---src-pages-service-logistics-logistics-js" */),
  "component---src-pages-service-marketing-components-creative-production-js": () => import("./../../../src/pages/service/Marketing/components/CreativeProduction.js" /* webpackChunkName: "component---src-pages-service-marketing-components-creative-production-js" */),
  "component---src-pages-service-marketing-components-marketing-content-js": () => import("./../../../src/pages/service/Marketing/components/MarketingContent.js" /* webpackChunkName: "component---src-pages-service-marketing-components-marketing-content-js" */),
  "component---src-pages-service-marketing-js": () => import("./../../../src/pages/service-marketing.js" /* webpackChunkName: "component---src-pages-service-marketing-js" */),
  "component---src-pages-service-marketing-marketing-js": () => import("./../../../src/pages/service/Marketing/Marketing.js" /* webpackChunkName: "component---src-pages-service-marketing-marketing-js" */),
  "component---src-pages-service-store-js": () => import("./../../../src/pages/service-store.js" /* webpackChunkName: "component---src-pages-service-store-js" */),
  "component---src-pages-service-store-store-js": () => import("./../../../src/pages/service/Store/Store.js" /* webpackChunkName: "component---src-pages-service-store-store-js" */),
  "component---src-pages-terms-of-service-components-terms-of-service-en-js": () => import("./../../../src/pages/termsOfService/components/TermsOfServiceEN.js" /* webpackChunkName: "component---src-pages-terms-of-service-components-terms-of-service-en-js" */),
  "component---src-pages-terms-of-service-components-terms-of-service-vn-js": () => import("./../../../src/pages/termsOfService/components/TermsOfServiceVN.js" /* webpackChunkName: "component---src-pages-terms-of-service-components-terms-of-service-vn-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

